* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f6f6f6;
  max-width: 100%;
  background-image: url("../images/bg/bg-body.webp");
}

/* scroll bar */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #adb5bd;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  height: 6rem;
  background-color: #333333;
}

/* Navbar stick */
.gradient {
  background: linear-gradient(
    180deg,
    rgba(14, 13, 13, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.navbar.stickyIn {
  position: fixed;
  opacity: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  animation-name: navbarStickyAnimIn;
  animation-duration: 0.5s;
}
.navbar.stickyOut {
  position: fixed;
  animation-name: navbarStickyAnimOut;
  animation-duration: 0.5s;
}
/* The animation code */
@keyframes navbarStickyAnimIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: unset;
  }
}

@keyframes navbarStickyAnimOut {
  0% {
    transform: unset;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* menu hover */
.navbar ul li:hover {
  box-shadow: rgba(230, 225, 228, 0.8) 0px 5px;
}

/* carousel */
.carousel-item {
  max-width: 100%;
  max-height: 100vh;
}
.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.1;
}
.carousel-caption {
  bottom: 60%;
  padding-left: 100px;
  padding-right: 100px;
}
.carousel-caption p {
  font-size: 45px;
  font-weight: 700;
  /* background:rgba(0,0,0,0.4); */
  background: linear-gradient(
    360deg,
    rgba(14, 13, 13, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border-radius: 10px;
}

/* info home */
.shadow-1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* fondo question */
#question {
  background-image: url("../images/bg/bg-question.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* Contacto */
.bg-contact {
  background-image: url("../images/bg/bg-contact-2.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* animacion de enlaces de contacto */
.animate__heartBeat {
  /* --animate-repeat: 3; */
  --animate-duration: 3s;
  --animate-delay: 3s;
}

#footer {
  background-color: rgb(40, 42, 53);
}

/* Gallery */
.container-gallery {
  margin-top: 50px;
}

/* React Masonry css*/
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  padding: 0 20px;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  background: grey;
  margin-bottom: 15px;
}

/* visualizador de Imagen */
.pics {
  transition: all 350ms ease;
  -webkit-transition: all 350ms ease;
  cursor: pointer;
}

.pics:hover {
  filter: opacity(0.6);
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 9999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  box-sizing: border-box;
  line-height: 0;
  margin: 0 auto;
  padding: 20px 0 20px;
}

.icon-close {
  color: white;
  font-size: 40px;
  cursor: pointer;
  position: fixed;
  top: 3%;
  right: 5%;
  padding: 5px;
}

.icon-next {
  color: white;
  opacity: 0.5;
  font-size: 80px;
  cursor: pointer;
  position: fixed;
  top: 50%;
  right: 10px;
  padding: 5px;
}
.icon-previous {
  color: white;
  opacity: 0.5;
  font-size: 80px;
  cursor: pointer;
  position: fixed;
  top: 50%;
  left: 10px;
  padding: 5px;
}

.icon-close:hover {
  color: red;
}
.icon-next:hover {
  opacity: 1;
}
.icon-previous:hover {
  opacity: 1;
}

.video-close {
  color: white;
  font-size: 40px;
  cursor: pointer;
}

.video-close:hover {
  color: red;
}

/* Spinner */
.sk-folding-cube {
  margin: 20px auto;
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  z-index: 999999;
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
